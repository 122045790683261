import React from 'react';
import { Link } from 'react-router-dom';

const Privacy = () => {
  return (
    <div className="font-calistoga text-white min-h-[100dvh] flex flex-col justify-between">

      <div className='absolute hidden md:block ml-40 mt-8'>
        <Link to="/"><h1 className="mb-6 text-4xl font-medium">Ebbinghaus</h1></Link>
      </div>

      <div className="flex flex-col w-screen md:flex-row md:w-[1200px] md:min-h-screen items-center md:justify-end mx-auto">
        <div className="flex flex-col items-center text-center max-w-80">
          <div className="px-5 flex flex-col items-center">

            <div className='block md:hidden mt-8 mb-2'>
              <Link to="/"><h1 className="mb-6 text-4xl font-medium">Ebbinghaus</h1></Link>
            </div>

            <div className='mb-6 text-3xl font-medium'>
              Privacy Policy
            </div>

            <div className='mb-6 text-xl font-semibold font-opensans'>
              Ebbinghaus does not collect any personally identifiable information from you
              <br /><br />
              Ebbinghaus does not use any analytics trackers
              <br /><br />
              Ebbinghaus does not use any cookies
              <br /><br />
              Ebbinghaus does not contain any adverts


            </div>

          </div>
        </div>
      </div>

      <footer className="bg-black bg-opacity-60 text-white text-center py-2 text-sm py-6">
        <a href="https://josephmalam.com/" target="_blank" className="px-2 m" rel="noopener noreferrer">&copy; Joseph Malam 2022-2024</a>
        <div className='flex flex-row items-center justify-center pt-2'>
          <Link to="/support" className="px-2 font-opensans">Support</Link>  &bull;
          <Link to="/privacy" className="px-2 font-opensans">Privacy Policy</Link>
        </div>
      </footer>
    </div>
  );
};

export default Privacy;
