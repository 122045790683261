import React from 'react';
import { Link } from 'react-router-dom';


const Home = () => {
  return (
    <div className="font-calistoga text-white min-h-screen flex flex-col justify-between">

      <div className='absolute hidden md:block ml-40 mt-8'>
        <Link to="/"><h1 className="mb-6 text-4xl font-medium">Ebbinghaus</h1></Link>
      </div>

      <div className="flex flex-col w-screen md:flex-row md:w-[1200px] min-h-screen items-center justify-end h-full mx-auto">

        <div className="flex flex-col items-center text-center">

          <div className="px-5 flex flex-col items-center">

            <div className='block md:hidden mt-8 mb-2'>
              <Link to="/"><h1 className="mb-6 text-4xl font-medium">Ebbinghaus</h1></Link>
              <h1 className="mb-6 text-xl font-medium">Streamline your memory<br/> with beautiful flashcards</h1>
            </div>

            <div className="md:block flex justify-center items-center relative">
              <div className="relative mx-auto">
                <img src="images/iphoneOutline.png" alt="iPhone Outline" className="relative w-72 z-20" />
                <video loop muted autoPlay className="absolute w-64 top-3.5 left-4 z-10 rounded-3xl">
                  <source src="/video.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>

              <div className="absolute -top-6 -right-6 -rotate-6 bg-red-500 text-white rounded-full w-24 h-24 z-40 flex items-center justify-center text-4xl font-bold shadow-2xl">
                <div className='flex flex-col items-center'>
                  <div className='font-medium text-base -mb-1'>100%</div>
                  <div className='font-medium text-3xl'>free!</div>
                </div>
              </div>
            </div>

            <div>
              <a href="https://apps.apple.com/gb/app/piano-dash/id1632441647" className="inline-block">
                <img src="images/appStore.svg" alt="Download on the App Store" className="h-16 mb-6 md:mb-0 mt-6" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <footer className="bg-black bg-opacity-60 text-white text-center py-2 text-sm py-6">
        <a href="https://josephmalam.com/" target="_blank" className="px-2 m" rel="noopener noreferrer">&copy; Joseph Malam 2022-2024</a>
        <div className='flex flex-row items-center justify-center pt-2'>
          <Link to="/support" className="px-2 font-opensans">Support</Link>  &bull;
          <Link to="/privacy" className="px-2 font-opensans">Privacy Policy</Link>
        </div>
      </footer>
    </div>
  );
};

export default Home;
